<template>
  <div class="p-2 bg-gray-200 dark:bg-gray-900">
    <div class="w-full p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm">
      <CustomerListModal :detail="selected" @refresh="getAll" />
      <div class="w-full">
        <LyrausTable
          :columns="columns"
          :data="List"
          :isLoading="load"
          :alternateRowBg="['white', 'gray-100']"
          :pageCount="pageCount"
          :pageProp="currentPage"
          @update-current-page="handleCurrentPage"
          :containerClass="'p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm'"
          :tdClass="'whitespace-nowrap border-gray-200 text-xs py-2'"
        >
          <template v-slot:rowButtons="{ item, index, columnKey }">
            <div v-if="columnKey === 'isDedicated'">
              <label class="cursor-pointer select-none">
                <input
                  type="checkbox"
                  :checked="item.isDedicated === 1"
                  @change="() => updateFleet(index, item, 'isDedicated')"
                  class="mr-3"
                />
              </label>
            </div>
            <div v-if="columnKey === 'isActive'">
              <label class="cursor-pointer select-none">
                <input
                  type="checkbox"
                  :checked="item.isActive === 1"
                  @change="() => updateFleet(index, item, 'isActive')"
                  class="mr-3"
                />
              </label>
            </div>
            <div v-if="columnKey === 'customers'">
              <button
                class="text-gray-700"
                @click="() => toggleCustomerList(item)"
              >
                <i class="fas fa-pen"></i>
              </button>
            </div>
            <div v-if="columnKey === 'customerCount'">
              <span>{{
                item.customers[0] === null ? 0 : item.customers.length
              }}</span>
            </div>
            <div v-if="columnKey === 'fleetIndex'">
              <span>{{ index + 1 }}</span>
            </div>
          </template>
        </LyrausTable>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { fleetManagement } from "@/networking/urlmanager";
import CustomerListModal from "./components/customerList.modal.vue";

export default {
  name: "FleetManagementModule",
  components: {
    CustomerListModal,
  },
  data() {
    return {
      load: false,
      columns: [
        {
          label: "#",
          key: "fleetIndex",
          width: 10,
          dontDisplay: true,
          center: true,
        },
        { label: "Filo Adı", key: "fleetName" },
        {
          label: "Müşteri Sayısı",
          key: "customerCount",
          dontDisplay: true,
          center: true,
          width: 40,
        },
        {
          label: "Müşterilere Özel",
          key: "isDedicated",
          dontDisplay: true,
          center: true,
          width: 40,
        },
        {
          label: "Müşteri Listesi",
          key: "customers",
          dontDisplay: true,
          center: true,
          width: 40,
        },
        // {
        //   label: "Aktif",
        //   key: "isActive",
        //   dontDisplay: true,
        //   center: true,
        //   width: 40,
        // },
      ],
      List: [],
      totalCount: 0,
      selected: null,
      pageCount: 1,
      currentPage: 0,
    };
  },
  methods: {
    handleCurrentPage(val) {
      this.currentPage = val;
    },
    async getAll() {
      this.load = true;
      try {
        const res = await axios.get(
          `${fleetManagement.getAll}?page=${this.currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.userData.token}`,
            },
          }
        );
        const { detail, totalCount, pageCount } = res.data.data;
        this.List = detail;
        this.totalCount = totalCount;
        this.pageCount = pageCount;
      } catch (err) {
        this.errorBox(err.response);
      } finally {
        this.load = false;
      }
    },
    async updateFleet(index, item, key) {
      this.load = true;
      const params = {
        rowId: item.id,
        isDedicated:
          key === "isDedicated"
            ? item.isDedicated === 1
              ? 2
              : 1
            : item.isDedicated,
        // isActive:
        //   key === "isActive" ? (item.isActive === 1 ? 2 : 1) : item.isActive,
      };

      try {
        await axios.post(fleetManagement.edit, params, {
          headers: {
            Authorization: `Bearer ${this.$store.state.userData.token}`,
          },
        });
        this.$set(this.List, index, { ...item, [key]: params[key] });
      } catch (err) {
        this.errorBox(err.response);
      } finally {
        this.load = false;
      }
    },
    toggleCustomerList(item) {
      this.selected = item;
      this.$modal.show("fleet-customers-modal");
    },
  },
  created() {
    this.getAll();
  },
  watch: {
    currentPage() {
      this.getAll();
    },
  },
};
</script>
