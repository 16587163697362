const {
  tracking,
  customer,
  product,
  routePlanner,
} = require("@/networking/urlmanager");
const { routeEngine } = require("@/networking/routeEngine");
const axios = require("axios");

let reTry = 4;
async function getLocationAll(token) {
  try {
    const result = await axios.get(tracking.getAll + "?isActive=true", {
      headers: {
        Authorization: "Bareer " + token,
      },
    });
    return result.data.data
      .map((item) => {
        return {
          ...item,
          isAvailable: true,
        };
      })
      .filter((item) => item.driverTC);
  } catch (error) {
    console.log(error.message);
    if (reTry > 0) {
      reTry--;
      setTimeout(async () => {
        return await getLocationAll(token);
      }, 1000);
    } else {
      alert("Araç verileri getirilemedi lütfen daha sonra tekrar deneyiniz!");
      return -1;
    }
  }
}
async function getCustomers(token) {
  try {
    const result = await axios.get(customer.getAllNotPagination, {
      headers: {
        Authorization: "Bareer " + token,
      },
    });
    return Array.isArray(result.data.data) ? result.data.data : [];
  } catch (error) {
    console.log(error.response);
    return [];
  }
}
async function getProducts(token) {
  try {
    const result = await axios.get(product.getWithFilterAll, {
      headers: {
        Authorization: "Bareer " + token,
      },
    });
    return Array.isArray(result.data.data) ? result.data.data : [];
  } catch (error) {
    console.log(error);
    return [];
  }
}
async function getRoute(
  from = { lat: 0, lng: 0 },
  to = { lat: 0, lng: 0 },
  isAdr = false,
  token
) {
  try {
    if (from.lat == 0 || from.lng == 0 || to.lat == 0 || to.lng == 0) {
      console.log(
        "Başlangıç ve bitiş konumları boş olduğundan dolayı rota hesaplaması yapılamadı"
      );
      return;
    }

    const detail = await axios.post(
      routeEngine.generate,
      {
        from,
        to,
        waypoints: [],
        isToll: 1,
        routeType: "fast",
        shippedHazardousGoods: isAdr,
        isShip: 1,
        isAsphalt: 1,
        fuelRatio: 35,
        isPolyline: 1,
        vehicleTypeId: 5, // tır
      },
      {
        headers: {
          Authorization: "Bareer " + this.$store.state.routeEngineToken,
        },
      }
    );
    return detail.data.data;
  } catch (error) {
    console.warn(error);
    return null;
  }
}

const yesNoOptions = [
  {
    id: 1,
    name: "Evet",
  },
  {
    id: 2,
    name: "Hayır",
  },
];
module.exports = {
  getLocationAll,
  getCustomers,
  getProducts,
  getRoute,
  yesNoOptions,
};
