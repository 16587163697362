<template>
  <tr colspan="3">
    <td class="table-td border-r border-opacity-20">#</td>
    <td class="table-td border-r border-opacity-20">
      <select
        v-model="selectedOrder"
        class="w-full roudned p-2 text-black rounded"
      >
        <option value="">Seçilmedi</option>
        <option v-for="item in order" :key="item.orderNo" :value="item.orderNo">
          {{ item.orderNo + " / " + item.name }}
        </option>
      </select>
    </td>
    <td class="table-td border-r border-opacity-20">
      <vehicleSelect
        v-if="selectedOrder > 0"
        v-model="vehicle"
        :optList="getFilterVehicle"
        :order="selectedOrderDetail"
        :products="products"
        :customers="customers"
        :load="vehicleLoad"
        :isBulk="true"
        :sorted="true"
        :warningControl="true"
        :selectedVehicles="selectedVehicles"
      />
    </td>

    <td class="table-td border-r border-opacity-20">
      {{ vehicle?.address }}
    </td>

    <td class="text-center">
      <button type="button" class="process-btn" @click="addItem()">
        <i class="fas fa-plus mr-1.5"></i> Ekle
      </button>
    </td>
  </tr>
</template>

<script>
//Local Components
import vehicleSelect from "../../../../../orders/components/vehicle-select.vue";
export default {
  name: "new-vehicle-row",
  props: ["order", "vehicleList", "customers", "products", "selectedVehicles"],
  components: {
    vehicleSelect,
  },
  data() {
    return {
      vehicle: "",
      vehicleLoad: false,
      dorse: "",
      driverName: "",
      driverTC: "",
      capacity: "",
      selectedOrder: null,
      selectedOrderDetail: {},
    };
  },
  methods: {
    addItem() {
      const item = this.order?.find(
        (item) => item.orderNo == this.selectedOrder
      );

      this.$emit("addItem", {
        orderNo: item.orderNo,
        orderName: item.name,
        outpointLat: item.outpointLat,
        outpointLng: item.outpointLng,
        ...this.vehicle,
      });
      this.vehicle = "";
    },
  },
  computed: {
    getFilterVehicle() {
      return this.vehicleList.map((item) => {
        return {
          name: item.vehicle,
          id: item.id,
          driverName: item.driverName,
          dorse: item.dorse,
          driverTC: item.driverTC,
          latitude: item.latitude,
          longitude: item.longitude,
          capacity: item.capacity,
          orderNos: item.orderNos,
          romorkType: item.romorkType,
          isRent: item?.haveType,
          lastProduct: item.lastProduct,
          address: item.address,
          isAvailable: item.isAvailable,
          vehicleInspectionStartDate: item?.vehicleInspectionStartDate,
          vehicleInspectionEndDate: item?.vehicleInspectionEndDate,
          inspectionStartDate: item.inspectionStartDate,
          inspectionEndDate: item.inspectionEndDate,
          vehicleAdrStartDate: item?.vehicleAdrStartDate,
          vehicleAdrEndDate: item?.vehicleAdrEndDate,
          dorseAdrStartDate: item?.dorseAdrStartDate,
          dorseAdrEndDate: item?.dorseAdrEndDate,
          transportPurpose: item.transportPurpose,
          orderNos: item.orderNos,
          fleetCustomers: item?.fleetCustomers,
          fleetId: item?.fleetId,
        };
      });
    },
  },
  watch: {
    selectedOrder(val) {
      const item = this.order?.find((item) => item.orderNo == val);
      this.selectedOrderDetail = item;
    },
  },
};
</script>
