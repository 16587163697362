<template>
  <diffInput
    v-model="selected"
    :optList="formattedList"
    title="Filo Seç"
    type="select"
    :loading="load"
  />
</template>

<script>
import diffInput from "@/components/general/diffInput.vue";
import axios from "axios";
import { fleetManagement } from "@/networking/urlmanager";

export default {
  name: "fleet-select",
  props: ["value"],
  components: {
    diffInput,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      selected: "",
      List: [],
      load: false,
    };
  },
  computed: {
    formattedList() {
      return this.List.map((item) => ({
        name: item.fleetName,
        ...item,
      }));
    },
  },
  methods: {
    getAll() {
      this.load = true;
      axios
        .get(fleetManagement.getAll, {
          headers: {
            Authorization: "Bearer " + this.$store.state.userData.token,
          },
        })
        .then((result) => {
          this.List = result.data.data.detail;
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
  created() {
    this.selected = this.value || "";
    this.getAll();
  },
  watch: {
    selected(val) {
      if (val && !this.load) {
        this.$emit("change", val);
      }
    },
    value(val) {
      this.selected = val;
    },
  },
};
</script>
