<template>
  <div>
    <div class="p-2 bg-gray-200 dark:bg-gray-900" @mousedown="closeDropdown">
      <!-- Modals -->
      <previewMapModal v-if="selectedPreview.id" :detail="selectedPreview" />
      <addModal @refresh="getAll()" />
      <editModal @refresh="getAll()" :rowId="selected.id" :isDisabled="true" />
      <vehicleStateModel
        :typeId="1"
        ref="vehicleStateModel"
        :Detail="selected"
      />
      <locationDetailModal
        :vehicle="selected.vehicle"
        :stateId="selected.stateId"
      />
      <changeVehicleModal
        :detail="selected"
        :order="selected"
        @refresh="getAll()"
      />
      <vehicleStatuModal @refresh="getAll()" ref="vehicleStatu" />
      <!-- Modals -->

      <div class="w-full">
        <div class="h-full p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm">
          <div class="w-full">
            <!-- Header -->
            <div class="w-full">
              <div
                class="h-full p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm"
              >
                <div class="w-full">
                  <LyrausTable
                    :data="List"
                    :columns="columns"
                    :innerTable="innerTableColumns"
                    :enablePagination="true"
                    :isLoading="load"
                    :stickyLeft="['orderNo']"
                    :stickyRight="['dropdownColumnDurum', 'buttonsColumn']"
                    :batchOperations="true"
                    :searchBar="true"
                    :searchProp="search"
                    @update-search-value="(val) => handleSearchValue(val)"
                    :handleSearch="handleSearch"
                    :alternateRowBg="['white', 'gray-100']"
                    :rightExtraSlot="true"
                    :dblClickableColumns="dblClickableColumns"
                    :pageCount="pageCount"
                    :pageProp="currentPage"
                    @update-current-page="handleCurrentPage"
                    :totalCount="totalCount"
                    @dbl-row-click="handleDblRowClick"
                    :buttonsColumn="{ label: 'İşlem', width: 120 }"
                    :dropdownColumns="[{ key: 'Durum', label: 'Durum' }]"
                    :paginationButtonColor="'red-600'"
                    :paginationHoverColor="'red-700'"
                    :containerClass="'p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm'"
                    :innerThClass="'py-2 text-left text-xxs font-medium bg-red-300 tracking-wider border-gray-200'"
                    :innerTdClass="'whitespace-nowrap text-xxs bg-red-200 py-2'"
                  >
                    <template v-slot:batchOperations>
                      <asyncBtn
                        icon="fas fa-plus "
                        :text="$t('operations.planning.newBtnTitle')"
                        class="w-36 btn"
                        @click="() => $modal.show('add-plan-modal')"
                      />
                    </template>
                    <template v-slot:rightExtra>
                      <div class="flex justify-between items-center">
                        <userFilterModal
                          ref="modalActive"
                          :selectedItems="selectedItems"
                          :load="load"
                          @selected-items-changed="handleSelectedItemsChanged"
                        />
                      </div>
                    </template>
                    <template v-slot:colDropdown="{ item, dropdownKey }">
                      <div
                        v-if="dropdownKey === 'dropdownColumnDurum'"
                        class="table-td w-36 border-r border-opacity-20"
                      >
                        <changeStatus
                          :statusList="statusList"
                          :plaque="item.vehicle"
                          :defaultTitle="item.stateName"
                          :stateId="item.stateId"
                          :typeId="item.typeId"
                          :planId="item.id"
                          :orderNo="item.orderNo"
                          :note="item.notes"
                          @changeVehicle="
                            () => (
                              (selected = item),
                              $modal.show('change-vehicle-modal')
                            )
                          "
                          @refresh="() => getAll()"
                          @vehicleStatu="
                            (r) => (
                              ($refs.vehicleStatu.modalData = {
                                detail: item,
                                activeStatu: r,
                              }),
                              $refs.vehicleStatu.show()
                            )
                          "
                        />
                      </div>
                    </template>
                    <template v-slot:sortButton="{ columnKey }">
                      <div
                        v-if="columnKey === searchColumn"
                        @click="clearSearch()"
                        class="cursor-pointer mr-1"
                      >
                        <i class="fas fa-times text-red-600"></i>
                      </div>
                      <button
                        v-if="columnKey === 'orderNo'"
                        class="pr-1"
                        @click="toggleSort(columnKey)"
                      >
                        <i
                          v-if="sortColumn !== 'orderNo'"
                          class="fas fa-sort"
                        ></i>
                        <i
                          v-if="sortState === 1 && sortColumn === 'orderNo'"
                          class="fas fa-sort-alpha-up"
                        ></i>
                        <i
                          v-if="sortState === 2 && sortColumn === 'orderNo'"
                          class="fas fa-sort-alpha-down"
                        ></i>
                      </button>
                      <button
                        v-if="columnKey === 'customer'"
                        class="pr-1"
                        @click="toggleSort(columnKey)"
                      >
                        <i
                          v-if="sortColumn !== 'customer'"
                          class="fas fa-sort"
                        ></i>
                        <i
                          v-if="sortState === 1 && sortColumn === 'customer'"
                          class="fas fa-sort-alpha-up"
                        ></i>
                        <i
                          v-if="sortState === 2 && sortColumn === 'customer'"
                          class="fas fa-sort-alpha-down"
                        ></i>
                      </button>
                      <button
                        v-if="columnKey === 'receivedName'"
                        class="pr-1"
                        @click="toggleSort(columnKey)"
                      >
                        <i
                          v-if="sortColumn !== 'receivedName'"
                          class="fas fa-sort"
                        ></i>
                        <i
                          v-if="
                            sortState === 1 && sortColumn === 'receivedName'
                          "
                          class="fas fa-sort-alpha-up"
                        ></i>
                        <i
                          v-if="
                            sortState === 2 && sortColumn === 'receivedName'
                          "
                          class="fas fa-sort-alpha-down"
                        ></i>
                      </button>
                      <button
                        v-if="columnKey === 'deliveredName'"
                        class="pr-1"
                        @click="toggleSort(columnKey)"
                      >
                        <i
                          v-if="sortColumn !== 'deliveredName'"
                          class="fas fa-sort"
                        ></i>
                        <i
                          v-if="
                            sortState === 1 && sortColumn === 'deliveredName'
                          "
                          class="fas fa-sort-alpha-up"
                        ></i>
                        <i
                          v-if="
                            sortState === 2 && sortColumn === 'deliveredName'
                          "
                          class="fas fa-sort-alpha-down"
                        ></i>
                      </button>
                      <button
                        v-if="columnKey === 'productType'"
                        class="pr-1"
                        @click="toggleSort(columnKey)"
                      >
                        <i
                          v-if="sortColumn !== 'productType'"
                          class="fas fa-sort"
                        ></i>
                        <i
                          v-if="sortState === 1 && sortColumn === 'productType'"
                          class="fas fa-sort-alpha-up"
                        ></i>
                        <i
                          v-if="sortState === 2 && sortColumn === 'productType'"
                          class="fas fa-sort-alpha-down"
                        ></i>
                      </button>
                      <button
                        v-if="columnKey === 'dropdownColumnDurum'"
                        class="pr-1"
                        @click="toggleSort('stateName')"
                      >
                        <i
                          v-if="sortColumn !== 'dropdownColumnDurum'"
                          class="fas fa-sort"
                        ></i>
                        <i
                          v-if="
                            sortState === 1 &&
                            sortColumn === 'dropdownColumnDurum'
                          "
                          class="fas fa-sort-alpha-up"
                        ></i>
                        <i
                          v-if="
                            sortState === 2 &&
                            sortColumn === 'dropdownColumnDurum'
                          "
                          class="fas fa-sort-alpha-down"
                        ></i>
                      </button>
                    </template>
                    <template v-slot:colButtons="{ item }">
                      <div class="flex items-center justify-end px-2">
                        <button
                          :class="
                            ![6, 7, 8].includes(item.stateId)
                              ? 'cursor-default opacity-20'
                              : ''
                          "
                          @click="
                            [6, 7, 8].includes(item.stateId)
                              ? showPreviewMapModal(item)
                              : null
                          "
                        >
                          <i class="fas fa-book text-gray-700"></i>
                        </button>
                      </div>
                      <div class="flex items-center justify-end px-2">
                        <button
                          class="mr-2 relative"
                          @click="showEditModal(item)"
                        >
                          <i
                            :class="
                              item.stateId >= 8 &&
                              'text-gray-700 text-opacity-50'
                            "
                            class="fas fa-pen"
                          ></i>
                          <i
                            v-if="item.explanation"
                            class="fas fa-circle text-xxs absolute -left-2 -top-1 text-red-600 animate-pulse"
                          ></i>
                        </button>
                      </div>

                      <div class="flex items-center justify-end px-2">
                        <button
                          class="mr-2"
                          :class="
                            item.isError > 0
                              ? 'animated animate-pulse duration-200'
                              : ''
                          "
                          @click="vehicleStatesModel(item)"
                        >
                          <i
                            class="fas fa-search"
                            :class="
                              item.isError > 0
                                ? 'text-red-500 animate-pulse duration-200'
                                : 'text-gray-700'
                            "
                          ></i>
                        </button>
                      </div>

                      <div class="flex items-center justify-end px-2">
                        <button
                          :disabled="item.stateId >= 8"
                          :class="item.stateId >= 8 && ' bg-opacity-70 mr-2'"
                          class="mr-2"
                          @click="deleteItem(item)"
                        >
                          <i
                            :class="
                              item.stateId >= 8 &&
                              'text-gray-400 text-opacity-50 '
                            "
                            class="fas fa-trash text-gray-700"
                          ></i>
                        </button>
                      </div>
                    </template>
                    <template v-slot:rowButtons="{ item, index, columnKey }">
                      <div
                        v-if="columnKey === 'vehicle'"
                        class="flex items-center justify-between"
                      >
                        <p
                          @click="item.vehicle ? handleItemClick(item) : ''"
                          :class="
                            item.stateId >= 8
                              ? 'opacity-40'
                              : 'cursor-pointer select-none'
                          "
                          class="border rounded-lg p-1 justify-between"
                        >
                          {{ item.vehicle }}
                          <i class="fas fa-search text-gray-700"></i>
                        </p>
                        <div class="ml-2 flex items-center justify-between">
                          <span
                            style="font-size: 8px"
                            class="px-2 mr-2 bg-red-600 rounded-xl text-white inline-block font-extralight"
                            v-if="item.isCustom == 1"
                          >
                            Manuel Atama
                          </span>
                        </div>
                      </div>
                    </template>
                    <template v-slot:filterButton="{ columnKey }">
                      <statusPanel
                        v-if="columnKey === 'dropdownColumnDurum'"
                        @stopFiltering="() => stopFiltering()"
                        :statusId="statusId"
                        :initialStatus="statusId"
                        :statusList="planningStatus"
                        @setStatusFilter="(val) => setStatusFilter(val)"
                      />
                    </template>
                  </LyrausTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Npm
import moment from "moment";

// Global Components
// import PaginationComponents from "@/components/general/pagination.vue";
// import tableLoader from "@/components/general/table-loader.vue";
// import searchInput from "@/components/general/search-input.vue";
// import tableResizeBtn from "@/components/general/table.resize.btn.vue";

//Global Components
import userEditModal from "@/components/modals/userEditModal/index.vue";
import userFilterModal from "@/components/filters/userFiltering/index.vue";
import statusPanel from "@/components/filters/filterTableStatus/index.vue";
import vehicleStatuModal from "@/components/modals/vehicleStatuModal/vehicleStatusModal.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";

// Local Components
import addModal from "./components/add.modal.vue";
import previewMapModal from "./components/preview-modal/index.vue";
import editModal from "./components/edit.modal.vue";
import changeStatus from "./components/change/status.vue";
import changeVehicleModal from "./components/change/vehicle.vue";
import vehicleStateModel from "./components/vehicleStateModel/index.vue";
import locationDetailModal from "./components/map-location.vue";

// Networking
import axios from "axios";
import { planning } from "@/networking/urlmanager";

export default {
  name: "planning-module",
  components: {
    // PaginationComponents,
    // tableLoader,
    // searchInput,
    // tableResizeBtn,
    vehicleStateModel,
    previewMapModal,
    addModal,
    editModal,
    changeStatus,
    locationDetailModal,
    changeVehicleModal,
    userEditModal,
    userFilterModal,
    vehicleStatuModal,
    statusPanel,
    asyncBtn,
  },
  data() {
    return {
      selectedItems: [],
      List: [],
      load: false,
      filterArr: [],
      sortState: 0,
      sortColumn: "",
      searchColumn: "",
      search: "",
      totalCount: 0,
      pageCount: 1,
      currentPage: 0,
      statusFilter: false,
      selectedStatusId: null,
      filterState: false,
      selectedPreview: {
        id: "",
        plaque: "",
        vehicleId: "",
        orderNo: "",
        outpointLat: "",
        outpointLng: "",
        targetPointLat: "",
        targetPointLng: "",
        deliveryDate: "",
        amount: "",
        customer: "",
        productType: "",
      },
      selected: {
        id: "",
        plaque: "",
        vehicleId: "",
        orderNo: "",
        outpointLat: "",
        outpointLng: "",
        targetPointLat: "",
        targetPointLng: "",
        deliveryDate: "",
        amount: "",
        customer: "",
        productType: "",
      },
      dblClickableColumns: [
        "customer",
        "receivedName",
        "deliveredName",
        "productType",
      ],
      columns: [
        {
          key: "orderNo",
          label: "#",
        },
        {
          key: "customer",
          label: "Müşteri",
          maxLength: 18,
        },
        {
          key: "receivedName",
          label: "Dolum",
          maxLength: 18,
        },
        {
          key: "deliveredName",
          label: "Teslim",
          maxLength: 18,
        },
        {
          key: "productType",
          label: "Ürün",
        },
        {
          key: "vehicle",
          label: "Araç",
          dontDisplay: true,
        },
      ],
      innerTableColumns: [
        { key: "orderName", label: "Kaynak" },
        { key: "outpointAdress", label: "Dolum Adresi" },
        { key: "targetAdress", label: "Teslim Adresi" },
        { key: "invoicedCompany", label: "Fatura Adresi" },
        { key: "deliveryDate", label: "Teslim Tarihi", isDate: true },
        { key: "driver", label: "Sürücü" },
        { key: "isTourComplatedSuccess", label: "Sefer Kaydı" },
      ],
      status: true,
      order: [
        {
          orderId: 1,
          orderName: "A-Z Sırala",
          active: false,
        },
        {
          orderId: 2,
          orderName: "Z-A Sırala",
          active: false,
        },
        {
          orderId: 0,
          orderName: "Varsayılan Sıralama",
          active: false,
        },
      ],
      planningStatus: [
        { id: 0, name: "Hepsi" },
        { id: 11, name: "Devam Edenler" },
        { id: 1, name: "Onay Bekliyor" },
        { id: 2, name: "Onaylandı" },
        { id: 3, name: "Yükleme Noktasına Gidiyor" },
        { id: 4, name: "Yüklemede " },
        { id: 5, name: "Yola Çıktı" },
        { id: 6, name: "Teslim Noktasına Ulaştı" },
        { id: 7, name: "Tahliyede" },
        { id: 8, name: "Tamamlandı" },
        { id: 9, name: "İptal Edildi" },
        { id: 10, name: "Onaylanmadı" },
      ],
      statusList: [],
      statusId: 0,
      currentIndex: 0,
      statusFilter: false,
      needInterval: true, // app.vue da setInterval için lazım silme
    };
  },
  methods: {
    closeDropdown() {
      this.statusFilter = false;
      this.$refs.modalActive.show();
    },
    showPreviewMapModal(item) {
      this.selectedPreview = item;
      this.$modal.show("preview-map-modal");
    },
    handleCurrentPage(val) {
      this.currentPage = val;
    },
    userEditModal(item) {
      // this.activeSpot = true;
      this.selected = item;
      this.$modal.show("ueserEdit-modal");
    },
    handleItemClick(item) {
      if (item.stateId < 8) {
        this.selected = item;
        this.$modal.show("location-detail-modal");
      }
    },
    vehicleStatesModel(item) {
      this.selected = item;
      this.$modal.show("vehicleState-modal");
    },
    toggleSort(column) {
      this.currentPage = 0;
      if (column === "stateName") {
        if (this.sortColumn !== "dropdownColumnDurum") {
          this.sortState = 0;
        }
        this.sortColumn = "dropdownColumnDurum";
      } else {
        if (this.sortColumn !== column) {
          this.sortState = 0;
        }
        this.sortColumn = column;
      }

      // Toggle the sort state between 0, 1, and 2
      this.sortState = (this.sortState + 1) % 3;
      // Determine the filter based on the sort state
      let filter = [];
      if (this.sortState === 1) {
        filter = [{ sort: 1, column }];
      } else if (this.sortState === 2) {
        filter = [{ sort: 2, column }];
      } else {
        this.sortColumn = "";
      }
      this.filterArr = [JSON.stringify(filter)];
      this.getAll();
    },
    showEditModal(item) {
      this.selected = item;
      setTimeout(() => {
        this.$modal.show("edit-plan-modal");
      }, 50);
    },
    getStatusList() {
      axios
        .get(planning.getStatusAll, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((result) => {
          if (this.typeId == 7 || this.typeId == 8) {
            this.statusList = result.data.data;
          } else {
            this.statusList = result.data.data.filter(
              (r) => r.id != 10 && r.id != 11
            );
          }
        })
        .catch((err) => {
          this.errorBox(err.response);
        });
    },
    handleSearchValue(val) {
      this.search = val;
    },
    handleSearch() {
      // this.clickSearch = false;
      this.searchColumn = "";
      this.currentPage = 0;
      this.getAll();
    },
    clearSearch() {
      this.search = "";
      this.handleSearch();
    },
    handleDblRowClick(data, key) {
      // this.clickSearch = true;
      this.search = "";
      this.currentPage = 0;
      this.searchColumn = key;
      this.search = data[key];
      this.getAll(true);
    },
    stopFiltering() {
      this.orderId = "";
      this.statusId = 0;
      this.currentIndex = 2;
      this.currentPage = 0;

      //this.selectedStatusId
      this.getAll();
    },
    // updateOrder(orderId) {
    //   this.statusId = 0;
    //   this.order.forEach((item) => {
    //     item.active = item.orderId === orderId;
    //   });
    //   this.statusFilter = false;
    //   // stateNameOrder'ı güncelle ve getAll fonksiyonunu çağır
    //   this.stateNameOrder = orderId;
    //   this.currentPage = 0;
    //   //this.selectedStatusId
    //   this.getAll();
    // },
    setStatusFilter(statusId) {
      this.statusId = statusId;
      if (this.statusId === 0) {
        this.currentIndex = 2;
      }

      this.currentPage = 0;
      this.statusFilter = false;
      this.load = true;
      this.getAll();
    },
    getAll(load = true) {
      // Statü parametresi verilmediyse veya statü değişiklik göstermiyorsa işlemi sonlandır
      if (this.statusId !== false) {
        if (this.status == true) {
          this.currentIndex = 2;
          this.status = false;
        }
        if (this.statusId === undefined) {
          // Sayfa değişikliğinde sadece sayfa parametresini güncelle ve filtreleme yapma
          this.statusId = this.selectedStatusId;
        } else {
          // eğer statü sıralaması yapılmışken order sıralaması kalmışsa sıfırlansın
          if (this.statusId !== 0) this.stateNameOrder = "";
          // Filtreleme yapıldığında seçilen statüyü sakla ve sayfa numarasını sıfırla
          this.selectedStatusId = this.statusId;
        }
        this.List = load ? [] : this.List;
        this.load = load;
        const params = {
          page: this.currentPage,
          search: this.search,
          stateNameOrder: this.stateNameOrder,
          userList: this.selectedItems,
          status: this.statusId,
        };
        if (this.sortState) {
          params.filter = this.filterArr;
        }
        if (this.searchColumn) {
          params.column = this.searchColumn;
        }
        axios
          .get(planning.getAll, {
            params,
            headers: {
              Authorization: "Bearer " + this.$store.state.userData.token,
            },
          })
          .then((res) => {
            this.List = res.data.data.detail;
            this.totalCount = res.data.data.totalCount;
            this.pageCount = res.data.data.pageCount;
          })
          .catch((err) => {
            this.errorBox(err.response);
            // this.notificationAlert(err); yeni alert
          })
          .finally(() => {
            this.load = false;
            // this.sendStatus = false;
          });
      }
    },
    filteredList() {
      return this.List.map((item) => {
        if (item.stateId !== 8) {
          return { ...item, isTourComplatedSuccess: "-" };
        }

        let isTourComplatedSuccess;

        switch (item.isTourComplatedSuccess) {
          case 1:
            isTourComplatedSuccess = "Kaydedildi";
            break;
          case 2:
          case null:
          case undefined:
            isTourComplatedSuccess = "Gönderiliyor";
            break;
          default:
            isTourComplatedSuccess = "-";
        }

        return { ...item, isTourComplatedSuccess };
      });
    },
    deleteItem(item) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "Planı silmek istediğinize emin misiniz ?",
        true,
        this.$t("general.confirmText"),
        this.$t("general.rejectText")
      ).then((acc) => {
        if (acc.isConfirmed) {
          axios
            .post(
              planning.delete,
              {
                rowId: item.id.toString(),
                orderNo: item.orderNo,
              },
              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            )
            .then((result) => {
              const detail = result.data.data;
              if (!detail.status) {
                this.swalBox(
                  "warning",
                  this.$t("general.warningTitle"),
                  result.data.message,
                  false,
                  this.$t("general.OkayTitle")
                );
              } else {
                this.swalBox(
                  "success",
                  this.$t("general.successTitle"),
                  "",
                  false,
                  this.$t("general.OkayTitle")
                );
              }
              if (!this.filterState) this.getAll();
              // else this.getFilter();
            })
            .catch((err) => {
              this.errorBox(err.response);
            });
        }
      });
    },
    handleSelectedItemsChanged(newSelectedItems) {
      this.selectedItems = newSelectedItems;
    },
  },
  created() {
    this.getStatusList();
    const tabIndex = this.$store.state.pages.List.findIndex(
      (i) => i.id === this.$route.name
    );
    if (tabIndex === -1) {
      this.getAll(false);
    }
  },
  beforeDestroy() {
    this.selectedPreview = {
      id: "",
      plaque: "",
      vehicleId: "",
      orderNo: "",
      outpointLat: "",
      outpointLng: "",
      targetPointLat: "",
      targetPointLng: "",
      deliveryDate: "",
      amount: "",
      customer: "",
      productType: "",
    };
  },
  watch: {
    currentPage() {
      if (!this.filterState) this.getAll();
    },
    filterState(val) {
      if (!val) this.getAll();
    },
    selectedItems() {
      this.getAll();
    },
  },
};
</script>
