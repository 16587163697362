<template>
  <div
    class="h-full flex items-center justify-center bg-white pt-6 md:pt-14 px-10 md:px-0"
    align="center"
  >
    <div class="w-full">
      <img
        class="md:w-4/12 max-sm:w-full pb-4"
        src="@/assets/logoseferi/logo.png"
        alt=""
      />
      <!-- <img class="md:w-4/12 max-sm:w-full  pb-4" src="@/assets/auth/güneyLogo.png" alt="" /> -->
      <p class="pt-0 pb-10 md:block hidden" style="color: #a8a8a8">
        Lütfen tüm alanları eksiksiz ve doğru olucak şekilde doldurunuz.
      </p>
      <form class="md:w-5/12 w-full" @submit.prevent="save">
        <div class="w-full text-left" align="center">
          <label style="color: #a8a8a8">{{ $t("user.username") }}</label>
          <div class="flex items-center mt-2">
            <VuePassword
              v-model="username"
              class="w-full rounded-xl"
              classes="h-12 bg-gray-50 dark:border-gray-800 text-lg focus:ring-0 rounded-lg dark:bg-gray-800 border-indigo-900"
              disableStrength
              type="text"
              disableToggle
            />
          </div>
        </div>
        <div class="w-full text-left mt-5" align="center">
          <label style="color: #a8a8a8">{{ $t("user.password") }}</label>
          <div class="flex items-center mt-2">
            <VuePassword
              v-model="password"
              class="w-full border-black dark:border-gray-800 rounded-r"
              classes="h-12 bg-gray-50 border-indigo-900 dark:border-gray-800 text-lg focus:ring-0 rounded-lg dark:bg-gray-800"
              disableStrength
            />
          </div>
        </div>

        <div class="text-left mt-2 mb-5 w-full flex items-center">
          <input
            type="checkbox"
            class="form-checkbox :bg-yellow-500 mr-2 rounded"
            style="color: #a8a8a8"
          />
          <label class="cursor-pointer select-none" style="color: #a8a8a8">
            {{ $t("user.rememberMe") }}
          </label>
          <div class="md:w-8/12 w-7/12 text-right">
            <button
              type="button"
              @click="$emit('fargotPassword')"
              class="hover:text-blue-900 border-b"
              style="color: #a8a8a8"
            >
              {{ $t("user.fargotPassword") }}
            </button>
          </div>
        </div>

        <div class="w-full mt-2">
          <asynBtn
            type="submit"
            :bgDark="true"
            :loadState="load"
            :loadTitle="$t('user.signInLoad')"
            :text="$t('user.signIn')"
            containerClass=" w-full asynBtn"
            icon="fas fa-sign-in-alt"
            style="background-color: #261f53"
          />
        </div>
      </form>
    </div>
  </div>
</template>
<script>
//npm
import VuePassword from "vue-password";

//global components
import asynBtn from "@/components/general/asyncBtn";

//networking
import axios from "axios";
import { user } from "@/networking/urlmanager.js";
export default {
  name: "authForm",
  components: {
    asynBtn,
    VuePassword,
  },
  data() {
    return {
      load: false,
      username: "",
      password: "",
      companyCode: "6",
    };
  },
  methods: {
    save() {
      if (this.username && this.password && this.companyCode) {
        this.load = true;
        axios
          .post(user.signIn, {
            username: this.username,
            password: this.password,
            companyId: this.companyCode,
          })
          .then((result) => {
            this.$store.commit("signIn", result.data.data);

            const filteredRoutes = this.$router.options.routes
              .filter(
                (item) =>
                  item.meta.module &&
                  this.$store.state.userData.perms.some(
                    (r) => r.editState != 0 && r.permtypeId == item.meta.module
                  )
              )
              .sort(function (a, b) {
                return a.meta.module - b.meta.module;
              });

            if (this.$store.state.userData.perms.length > 0) {
              this.$store.commit("complateFirstAuth");
              this.$router.push(filteredRoutes[0]);
            } else {
              this.$store.commit("complateFirstAuth");
              this.$store.commit("generalPermError");

              this.$router.push("/general-perm-error");
            }
            this.load = false;
          })
          .catch((err) => {
            this.errorBox(err.response);
            this.load = false;
          });
      } else {
        this.swalBox(
          "warning",
          "UYARI",
          "Lütfen tüm alanları eksiksiz olarak doldurunuz",
          false,
          "Tamam"
        );
      }
    },
  },
  created() {
    if (this.$store.state.isAuth) {
      const filteredRoutes = this.$router.options.routes
        .filter(
          (item) =>
            item.meta.module &&
            this.$store.state.userData.perms.some(
              (r) => r.editState != 0 && r.permtypeId == item.meta.module
            )
        )
        .sort(function (a, b) {
          return a.meta.module - b.meta.module;
        });

      this.$store.commit("complateFirstAuth");
      this.$router.push(filteredRoutes[0]);
    }
  },
};
</script>
<style>
.asynBtn:hover {
  opacity: 0.5;
}
</style>
