<template>
  <div>
    <div v-if="!load">
      <StateHistory
        :typeId="item.isBulkOperation ? 2 : 1"
        ref="stateModal"
        :Detail="detail"
      />
      <h4 class="w-full block mb-2 pb-2 border-b text-xs">
        Yük Türü
        <b class="block font-bold mt-1">
          {{
            item.isBulkOperation == 1 ? "Toplu Sevkiyat" : "Spot Sevkiyat"
          }}</b
        >
      </h4>
      <h4 class="w-full block mb-2 pb-2 border-b text-xs">
        Sipariş Numarası
        <b class="block font-bold mt-1"> {{ item.orderNo }}</b>
      </h4>

      <h4 class="w-full block mb-2 pb-2 border-b text-xs">
        Yükleme Yeri
        <b v-if="!showFullOutpoint" class="block font-bold mt-1">
          <span v-if="!showFullOutpoint">
            {{
              item.outpointAdress.substring(
                item.outpointAdress.lastIndexOf(
                  " ",
                  item.outpointAdress.lastIndexOf(" ") - 1
                ) + 1
              )
            }}
          </span>
        </b>
        <b v-else class="block font-bold mt-1"> {{ item.outpointAdress }}</b>
        <button
          v-if="item.outpointAdress.length > 20"
          @click="showFullOutpoint = !showFullOutpoint"
          class="text-red-500"
        >
          {{ showFullOutpoint ? "Daha az göster" : "Daha çok göster" }}
        </button>
      </h4>

      <h4 class="w-full block mb-2 pb-2 border-b text-xs">
        Boşaltma Yeri
        <b v-if="!showFullTarget" class="block font-bold mt-1">
          <span v-if="!showFullTarget">
            {{
              item.targetAdress.substring(
                item.targetAdress.lastIndexOf(
                  " ",
                  item.targetAdress.lastIndexOf(" ") - 1
                ) + 1
              )
            }}
          </span>
        </b>
        <b v-else class="block font-bold mt-1"> {{ item.targetAdress }}</b>
        <button
          v-if="item.targetAdress.length > 20"
          @click="showFullTarget = !showFullTarget"
          class="text-red-500"
        >
          {{ showFullTarget ? "Daha az göster" : "Daha çok göster" }}
        </button>
      </h4>

      <h4 class="w-full block mb-2 pb-2 border-b text-xs">
        Müşteri
        <b class="block font-bold mt-1"> {{ detail.customer }}</b>
      </h4>
      <h4 class="w-full block mb-2 pb-2 border-b text-xs">
        Ürün
        <b class="block mt-1"> {{ detail.product }}</b>
      </h4>
      <!-- <h4 class="w-full block mb-2 pb-2 border-b text-sm">
        Sürücü
        <b class="block"> {{ detail.driverName }}</b>
      </h4> -->
      <button
        @click="showStatusHistory()"
        class="w-full block mb-2 pb-2 border-b text-xs text-left hover:bg-opacity-10 hover:bg-black hover:rounded transition-all duration-200"
      >
        <label>Durum</label>
        <div class="flex justify-between items-center">
          <b class="block text-red-500"> {{ detail.stateName }}</b>
          <i class="fas fa-search text-xl text-black text-opacity-50"></i>
        </div>
      </button>
      <!-- <h4 class="w-full block mb-2 pb-2 border-b text-sm">
        Dorse
        <b class="block"> {{ detail.dorsePlaque }}</b>
      </h4> -->

      <div v-if="detail.state != 4 && detail.state < 6">
        <h4 class="w-full block mb-2 pb-2 border-b text-sm">
          Yetişme Durumu
          <b
            class="block uppercase animate-pulse text-xs mt-1"
            :class="diffStatus ? 'text-green-600' : 'text-red-600'"
          >
            {{
              (detail.state <= 3 ? "Doluma " : "Teslime ") +
              (diffStatus ? "Yetişecek" : "Yetişemez")
            }}</b
          >
        </h4>
        <h4 class="w-full block mb-2 pb-2 border-b text-sm">
          {{
            detail.state <= 3
              ? "Hedef Doluma Giriş Zamanı"
              : "Hedef Teslim Tarihi"
          }}
          <b class="block text-xs mt-1">
            {{ detail.targetDate | dateFormat }}</b
          >
        </h4>
        <h4 v-if="!diffStatus" class="w-full block mb-2 pb-2 border-b text-sm">
          {{
            detail.state <= 3 ? "Tahmini Dolum Zamanı" : "Tahmini Teslim Tarihi"
          }}
          <b class="block text-xs mt-1">
            {{ calculatePosition.totalMinute | dateFormat }}</b
          >
        </h4>
      </div>
    </div>

    <label v-else class="text-center block">
      <i class="fas fa-spinner fa-spin"></i>
      <span class="text-center mt-1 block"> Yükleniyor </span>
    </label>
  </div>
</template>

<script>
// NPM
import moment from "moment";

// Networking
import axios from "axios";
import { routePlanner } from "@/networking/urlmanager";
import { routeEngine } from "@/networking/routeEngine";

// ???
import StateHistory from "../../../operations/planning/components/vehicleStateModel/index.vue";
import { decode } from "../../../../utils/encoder";

export default {
  name: "current-detail",
  props: ["item"],
  components: {
    StateHistory,
  },
  data() {
    return {
      detail: {
        customer: "",
        product: "",
        driverName: "",
        stateName: "",
        dorsePlaque: "",
        state: "",
      },
      orderNo: "",
      calculatePosition: {},
      diffStatus: false,
      load: false,
      showFullOutpoint: false,
      showFullTarget: false,
    };
  },
  created() {
    this.detail = this.item.planning;

    this.getRoute();
  },
  methods: {
    getRoute() {
      this.load = true;
      axios
        .post(
          routeEngine.generate,
          {
            from: this.item.position,
            avoidPoints: [],
            vehicleTypeId: 5,
            to:
              this.detail.state < 5
                ? {
                    lat: parseFloat(this.item.outpointLat),
                    lng: parseFloat(this.item.outpointLng),
                  }
                : {
                    lat: parseFloat(this.item.targettLat),
                    lng: parseFloat(this.item.targettLng),
                  },
            waypoints: [],
            isToll: 1,
            routeType: "fast",
            shippedHazardousGoods: "0",
            isShip: 1,
            isAsphalt: 1,
            fuelRatio: 35,
            isPolyline: 1,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.routeEngineToken,
            },
          }
        )
        .then((res) => {
          this.load = true;
          const detail = res.data.data;
          this.calculatePosition = detail;
          this.calculatePosition.totalMinute = moment().add(
            detail.totalMinutes,
            "minutes"
          );

          var duration = moment.duration(
            moment(this.detail.targetDate).diff(
              this.calculatePosition.totalMinute
            )
          );
          this.diffStatus = duration.asMinutes() > 0;

          this.$emit("selectedActiveDetail", {
            state: this.detail.state,
            targetPoint:
              this.detail.state < 5
                ? {
                    lat: parseFloat(this.item.outpointLat),
                    lng: parseFloat(this.item.outpointLng),
                  }
                : {
                    lat: parseFloat(this.item.targettLat),
                    lng: parseFloat(this.item.targettLng),
                  },
            polyline: decode(detail.routePolyline).polyline,
            customer: this.detail.customer,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.load = false;
        });
    },
    showStatusHistory() {
      this.$modal.show("vehicleState-modal");
    },
  },
  filters: {
    dateFormat(val) {
      return moment(val).format("LLL");
    },
  },
};
</script>
