import LZString from "lz-string";

export default {
  namespaced: true,
  state: () => ({
    List: [],
  }),
  mutations: {
    addPagesDetail(state, val) {
      if (val.id === "vehicleTrackingPage") {
        return;
      }
      const findIndex = state.List.findIndex((r) => r.id == val.id);
      const compressedValue = LZString.compress(JSON.stringify(val.data));
      if (findIndex === -1) {

        state.List.push({
          id: val.id,
          data: compressedValue,
        });
      } else {
        state.List[findIndex].data = compressedValue;
      }
    },
    removePage(state, val) {
      const findIndex = state.List.findIndex((r) => r.id == val.id);
      if (findIndex > -1) state.List.splice(findIndex, 1);
    },
    clearPage(state) {
      state.List = [];
    },
  },
};
