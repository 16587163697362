<template>
  <div>
    <userEditModal
      :rowId="rowId"
      :orderNo="orderNo"
      :vehicle="selected"
      @refresh="getAll"
      :activeSpot="activeSpot"
    />
    <addModal
      :orderItem="orderItem"
      :customers="customers"
      :customerList="customerList"
      :orderNo="orderNo"
      :rowId="rowId"
      @refresh="() => ($emit('refresh', true), getAll())"
    />
    <div class="w-full mt-4">
      <div class="flex w-full justify-between">
        <div class="flex items-center gap-4 ml-4">
          <button
            @click="$modal.show('add-vehicle-modal')"
            class="text-center"
            :disabled="isDisabled"
            :class="isDisabled ? 'btn-disabled' : 'btn'"
          >
            <i class="fas fa-plus"></i> Yeni Araç
          </button>
          <!-- Updated Search Input with Enter key handling -->
          <div class="relative">
            <input
              type="text"
              v-model="searchQuery"
              @keyup.enter="handleSearch"
              placeholder="Ara ve Enter'a bas..."
              class="px-4 py-2 pr-10 rounded border border-gray-300 focus:outline-none focus:border-blue-500 w-64"
            />
            <i class="fas fa-search absolute right-3 top-3 text-gray-400"></i>
          </div>
        </div>
        <div class="w-80 mr-4 flex gap-4">
          <asyncBtn
            @click="stopControl()"
            :loadState="setLoad"
            text="Tümünü Durdur"
            icon=""
            size="py-1 "
            containerClass="w-40 h-10"
          />
          <asyncBtn
            @click="startControl()"
            :loadState="setLoad"
            text=" Tümünü Başlat"
            icon=""
            size="py-1 "
            containerClass=" bg-green-700 hover:bg-green-500 w-40 h-10"
          />
        </div>
      </div>
      <div
        class="shadow table-height-value border-b overflow-y-scroll border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4 mb-4 mx-4"
      >
        <table class="min-w-full divide-y divide-gray-200">
          <thead
            class="bg-white border-black border-dotted border-b-2 border-opacity-20"
          >
            <tr>
              <th scope="col" class="table-th text-center">#</th>
              <th
                scope="col"
                class="table-th text-left border-r border-opacity-20"
                style="min-width: 190px !important"
              >
                Araç
              </th>
              <th
                scope="col"
                class="table-th text-left border-r border-opacity-20"
                style="min-width: 100px !important"
              >
                Dorse
              </th>
              <th
                scope="col"
                class="table-th text-left border-r border-opacity-20"
                style="min-width: 160px !important"
              >
                Sürücü
              </th>
              <th
                scope="col"
                class="table-th text-left border-r border-opacity-20"
                style="min-width: 120px !important"
              >
                Sefer Sayısı
              </th>
              <th
                scope="col"
                class="table-th text-left border-r border-opacity-20"
                style="min-width: 180px !important"
              >
                Başlat / Durdur
              </th>
              <th
                scope="col"
                class="table-th text-left border-r border-opacity-20"
              >
                #
              </th>
            </tr>
          </thead>
          <tbody
            class="divide-y-2 divide-gray-500"
            style="background-color: #3a3a3a"
          >
            <tr v-for="(item, index) in List" :key="item.id">
              <td class="table-td text-center border-r border-opacity-20">
                {{ index + 1 + currentPage * 20 }}
              </td>
              <td class="table-td border-r border-opacity-20">
                <div class="flex justify-between items-center">
                  <p>{{ item.plaque }}</p>
                  <span
                    class="px-2 bg-red-600 rounded-xl text-white text-xs inline-block font-extralight"
                    v-if="item.isCustom == 1 || item.isReject == 1"
                  >
                    {{
                      item?.isReject == 1
                        ? item?.changeRejectId == 1
                          ? "Reddetti"
                          : "İptal Edildi"
                        : "Manuel Atama"
                    }}
                  </span>
                </div>
              </td>
              <td class="table-td border-r border-opacity-20">
                <p>{{ item.dorse }}</p>
              </td>
              <td class="table-td border-r border-opacity-20">
                <p>{{ item.name }}</p>
              </td>
              <td
                class="table-td border-r border-opacity-20 font-bold text-center"
              >
                <p>{{ item.transitionCount }}</p>
              </td>
              <td
                class="table-td border-r border-opacity-20 font-bold text-center"
              >
                <asyncBtn
                  :loadState="setLoad"
                  @click="() => (stopedControl(item), $emit('refresh', true))"
                  :text="item.isStopped == 2 ? 'Durdur' : 'Başlat'"
                  icon=""
                  size="py-1 "
                  :containerClass="
                    item.isStopped === 1
                      ? 'bg-green-700 hover:bg-green-500 w-40'
                      : 'w-40'
                  "
                />
              </td>
              <td class="table-td border-r border-opacity-20 flex">
                <button
                  :class="
                    isDisabled
                      ? 'text-white border rounded px-2 py-1 border-opacity-30 bg-opacity-30 text-opacity-30 mr-2 cursor-default'
                      : 'process-btn mr-2'
                  "
                  @click="userEditModal(item)"
                >
                  <i class="fas fa-pen"></i>
                </button>
                <button
                  :class="
                    isDisabled
                      ? 'text-white border rounded px-2 py-1 border-opacity-30 bg-opacity-30 text-opacity-30 cursor-default'
                      : 'process-btn'
                  "
                  :disabled="isDisabled"
                  @click="deleteItem(item)"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </td>
            </tr>
            <tableLoader :load="load" :length="List.length" colspan="7" />
          </tbody>
        </table>
      </div>
      <div class="px-4">
        <PaginationComponents
          v-if="totalCount > 0"
          :totalRowCount="totalCount"
          :pageCount="pageCount"
          :currentPage="currentPage + 1"
          @changePage="(r) => (currentPage = r - 1)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
//Global Components
import tableLoader from "@/components/general/table-loader.vue";
import PaginationComponents from "@/components/general/pagination.vue";
import userEditModal from "@/components/modals/userEditModal/index.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
// local components
import addModal from "../add.vue";
//Networking
import axios from "axios";
import { bulkPlanning } from "@/networking/urlmanager";

export default {
  props: [
    "rowId",
    "isDisabled",
    "orderNo",
    "customerList",
    "customers",
    "orderItem",
    "dataLoad",
  ],
  components: {
    tableLoader,
    PaginationComponents,
    addModal,
    userEditModal,
    asyncBtn,
  },
  data() {
    return {
      load: false,
      List: [],
      totalCount: 0,
      pageCount: 0,
      currentPage: 0,
      selected: "",
      activeSpot: false,
      newisStopped: 2,
      setLoad: false,
      isAllStopped: 1,
      type: 1,
      searchQuery: "",
    };
  },
  methods: {
    handleSearch() {
      this.currentPage = 0;
      this.getAll();
    },

    getAll(isStart = false) {
      if (isStart) this.currentPage = 0;

      this.List = [];
      this.load = true;

      const params = new URLSearchParams({
        rowId: this.rowId,
        page: this.currentPage,
        search: this.searchQuery,
      });

      axios
        .get(`${bulkPlanning.vehiclesAll}?${params.toString()}`, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.List = res.data.data.detail;
          this.totalCount = res.data.data.totalCount;
          this.pageCount = res.data.data.pageCount;
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    stopControl() {
      this.isAllStopped = 1;
      this.type = 2;
      this.orderStatus(false);
    },
    startControl() {
      this.isAllStopped = 2;
      this.type = 2;
      this.orderStatus(false);
    },
    stopedControl(item) {
      if (item.isStopped == 2) {
        this.type = 1;
        this.newisStopped = 1;
        this.isAllStopped = 0;
        this.orderStatus(true, item);
      } else {
        this.newisStopped = 2;
        this.type = 1;
        this.isAllStopped = 0;
        this.orderStatus(true, item);
      }
    },
    orderStatus(isSingle, item) {
      this.setLoad = true;
      let newItem = [item];
      axios
        .post(
          bulkPlanning.vehicleSet,
          {
            isStopped: isSingle ? this.newisStopped : 0,
            isAllStopped: !isSingle ? this.isAllStopped : 0,
            bulkId: this.rowId,
            orderNo: this.orderNo,
            list: this.type == 1 ? newItem : this.List,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          );
          this.setLoad = false;
          this.getAll();
        })
        .catch((err) => {
          this.errorBox(err.response);
          this.setLoad = false;
        });
    },
    userEditModal(item) {
      this.activeSpot = false;
      this.selected = item.plaque;
      this.$modal.show("ueserEdit-modal");
    },
    deleteItem(item) {
      this.load = true;
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        ` ${item.plaque} Plakalı Aracı  planlamadan çıkarmak istediğinize emin misiniz?`,
        true,
        this.$t("general.confirmText"),
        this.$t("general.rejectText")
      ).then((acc) => {
        if (acc.isConfirmed) {
          axios
            .post(
              bulkPlanning.vehicleDelete,
              {
                rowId: item.id.toString(),
                plaque: item.plaque.toString(),
                bulkId: this.rowId,
                orderNo: this.orderNo,
                driverTc: item.driverTC,
              },
              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            )
            .then(() => {
              this.swalBox(
                "success",
                this.$t("general.successTitle"),
                "",
                false,
                this.$t("general.OkayTitle")
              );
              this.getAll();
            })
            .catch((err) => {
              this.errorBox(err.response);
            });
        }
      });
    },
  },
  created() {
    this.getAll();
  },
  watch: {
    currentPage() {
      this.getAll();
    },
  },
  filters: {
    dateFormat(val) {
      return moment(val).format("LLLL");
    },
  },
};
</script>
