<template>
  <div class="min-h-80 p-2 bg-gray-200 dark:bg-gray-900">
    <zoneEditModal v-if="selected" :detail="selected" @refresh="getAll()" />
    <div class="h-full p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm">
      <div class="w-full">
        <LyrausTable
          :data="List"
          :columns="columns"
          :enablePagination="true"
          :searchBar="true"
          :searchProp="search"
          @update-search-value="(val) => handleSearchValue(val)"
          :handleSearch="handleSearch"
          :isLoading="load"
          :pageCount="pageCount"
          :pageProp="currentPage"
          @update-current-page="handleCurrentPage"
          :totalCount="totalCount"
          :alternateRowBg="['white', 'gray-100']"
          :batchOperations="true"
          :rightExtraSlot="true"
          :buttonsColumn="{ label: 'İşlem' }"
          :paginationButtonColor="'red-600'"
          :paginationHoverColor="'red-700'"
          :containerClass="'p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm'"
          :innerThClass="'py-2 text-left text-xxs font-medium bg-red-300 tracking-wider border-gray-200'"
          :innerTdClass="'whitespace-nowrap text-xxs bg-red-200 py-2'"
        >
          <template v-slot:colButtons="{ item }">
            <div class="flex items-center justify-end space-x-2 px-2">
              <button class="mr-2" @click="showEditModal(item)">
                <i class="fas fa-pen text-gray-700"></i>
              </button>
            </div>
          </template>
          <template v-slot:rowButtons="{ item, columnKey }">
            <div v-if="columnKey === 'polyline'">
              <i v-if="item.polyline" class="fas fa-check text-green-400"></i>
              <i v-else class="fas fa-times text-red-400"></i>
            </div>
          </template>
          <template v-slot:batchOperations>
            <div class="w-full ml-2">
              <asyncBtn
                icon="fas fa-file-excel"
                text="Excel Çıktısı Al"
                loadTitle="Yükleniyor"
                :loadState="load"
                class="w-full md:w-40 bg-green-700"
                size="py-2 px-2"
                type="button"
                @click="excelExport()"
              />
            </div>
          </template>
        </LyrausTable>
      </div>
    </div>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

// Global Components
import asyncBtn from "@/components/general/asyncBtn.vue";

// Networking
import axios from "axios";
import { routeZone } from "@/networking/urlmanager";

// Local Components
import zoneEditModal from "./components/zoneEditModal.vue";

export default {
  name: "branches-module",
  components: {
    asyncBtn,
    zoneEditModal,
  },
  data() {
    return {
      load: false,
      search: "",
      selected: { id: "" },
      List: [],
      excelList: [],
      totalCount: 0,
      pageCount: 1,
      currentPage: 0,
      columns: [
        {
          key: "id",
          label: "ID",
        },
        {
          key: "address",
          label: "Adres",
        },
        {
          key: "name",
          label: "Bölge Adı",
        },
        {
          key: "latitude",
          label: "Enlem",
        },
        {
          key: "longitude",
          label: "Boylam",
        },
        {
          key: "polyline",
          label: "Bölge",
          dontDisplay: true,
          center: true,
        },
      ],
    };
  },
  methods: {
    showEditModal(item) {
      this.selected = item;
      setTimeout(() => {
        this.$modal.show("route-report-modal");
      }, 100);
    },
    handleSearch() {
      this.clickSearch = false;
      this.searchColumn = "";
      this.currentPage = 0;
      this.getAll();
    },
    handleCurrentPage(val) {
      this.currentPage = val;
    },
    handleSearchValue(val) {
      this.search = val;
    },
    getAll() {
      this.List = [];
      this.load = true;
      const params = {
        page: this.currentPage,
        search: this.search,
      };
      axios
        .get(routeZone.getAll, {
          params,
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.List = res.data.data.detail;
          this.totalCount = res.data.data.totalCount;
          this.pageCount = res.data.data.pageCount;
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },

    getExcelData() {
      const params = {
        search: this.search,
      };

      // Return the Promise from axios
      return axios
        .get(routeZone.getAllData, {
          params,
          headers: {
            Authorization: "Bearer " + this.$store.state.userData.token, // Fixed typo in "Bearer"
          },
        })
        .then((res) => {
          return res.data.data; // This will now be properly returned
        })
        .catch((err) => {
          this.errorBox(err.response);
          return []; // Return empty array in case of error
        })
        .finally(() => {
          this.load = false;
        });
    },

    async excelExport() {
      try {
        const workbook = XLSX.utils.book_new();

        const headers = {
          id: "ID",
          address: "Adres",
          name: "Bölge Adı",
          latitude: "Enlem",
          longitude: "Boylam",
          polyline: "Bölge",
        };

        const excelData = await this.getExcelData();

        if (!excelData || !Array.isArray(excelData)) {
          throw new Error("Invalid data received");
        }

        // Create worksheet data with headers and rows
        const worksheet = XLSX.utils.aoa_to_sheet([
          Object.values(headers), // Headers row
          ...excelData.map((item) => [
            item.id || "",
            item.address || "",
            item.name || "",
            item.latitude || "",
            item.longitude || "",
            item.polyline ? "Var" : "Yok",
          ]),
        ]);

        // Add worksheet to workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "kilometerRaports");

        // Create and save the file
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });

        const data = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        saveAs(data, "route-zones.xlsx");
      } catch (error) {
        console.error("Excel export failed:", error);
        // Handle error appropriately (maybe show an error message to user)
        if (this.errorBox) {
          this.errorBox(error);
        }
      }
    },
  },
  created() {
    this.getAll();
  },
  watch: {
    currentPage() {
      this.getAll();
    },
  },
};
</script>
