<template>
  <div class="w-full">
    <div
      class="w-full py-3 select-none cursor-pointer px-2 text-xs rounded my-2"
      v-for="item in List"
      :key="item.fleetId"
      :class="
        selected == item && selectedGroup
          ? 'bg-gray-100 shadow border-b'
          : (selected == item || selected == item.fleetId) &&
            !openSub &&
            !selectedGroup
          ? 'border-l-4 bg-gray-100 shadow border-red-500'
          : selected == item && openSub && 'bg-gray-100 shadow'
      "
    >
      <div @click="() => getDetail(item)">
        <i
          class="fas text-black text-opacity-50 mr-2"
          :class="item == selected && openSub ? 'fa-minus' : 'fa-plus'"
        ></i>
        {{ item.fleetName }}
      </div>

      <div
        class="px-4 text-black mt-5"
        v-if="openSub && item == selected && item.fleetId != null"
      >
        <div class="w-full flex items-center justify-start" v-if="load">
          <i class="fas fa-spinner mr-2 fa-spin"></i> Yükleniyor
        </div>

        <div
          @click="selectedGroup = groupItem"
          v-for="(groupItem, index) in subList"
          :key="index"
          class="my-2 px-5 cursor-pointer select-none"
          :class="selectedGroup == groupItem && 'border-l-4 border-red-500'"
        >
          {{ groupItem.groupName }}
        </div>
      </div>
    </div>

    <div class="text-center text-xs mt-2" v-if="List.length <= 0 && !load">
      Hehrangi bir grup bilgisi bulunamadı

      <button
        class="mt-2 font-bold bg-red-500 px-2 py-1 rounded text-white"
        @click="getAll()"
      >
        Yeniden Dene
      </button>
    </div>

    <div
      class="w-full flex items-center justify-center px-2 py-4"
      v-else-if="List.length <= 0 && load"
    >
      <i class="fas fa-spinner mr-2 fa-spin"></i> Yükleniyor
    </div>
  </div>
</template>

<script>
// Networking
import axios from "axios";
import { tracking } from "@/networking/urlmanager";
export default {
  name: "group-panel",
  data() {
    return {
      List: [],
      subList: [],

      load: false,
      openSub: false,

      selectedGroup: null,
      selected: null,
    };
  },
  methods: {
    getAll() {
      this.load = true;
      axios
        .get(tracking.getFleetAll, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((response) => {
          this.List = [
            {
              fleetId: null,
              fleetName: "Tümü",
            },
          ];
          this.List.push(...response.data.data);
          this.load = false;
        })
        .catch((err) => {
          console.log(err);
          this.load = false;
        });
    },
    getDetail(item) {
      this.subList = [];
      this.selected = item;

      this.$emit("changeFleet", item?.fleetId);

      // tümü seçildiyse çalıştırma
      if (item.fleetId == null) return;

      this.load = true;
      this.openSub = !this.openSub;
      this.selectedGroup = null;
      axios
        .get(tracking.getGroupAll + "?fleetId=" + this.selected.fleetId, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((response) => {
          this.subList = response.data.data;
          this.load = false;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
  created() {
    this.getAll();
  },
  watch: {
    selectedGroup(val) {
      this.$emit("changeGroup", val?.groupId);
    },
    // selected(val) {
    //   this.$emit("changeFleet", val?.fleetId);
    // },
  },
};
</script>
