<template>
  <modal
    name="location-detail-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '60%'"
    :scrollable="true"
    @opened="getAll()"
    @closed="reset()"
  >
    <div class="w-full relative">
      <button
        class="p-2 absolute right-5 top-3 z-50"
        @click="$modal.hide('location-detail-modal')"
        :class="$store.state.mapView == true ? 'text-white ' : 'text-black'"
      >
        <i class="fas fa-times fa-2x"></i>
      </button>
      <div
        class="w-full flex items-center justify-center"
        style="height: 50vh"
        v-if="load"
      >
        <i class="fas fa-spinner fa-3x fa-spin"></i>
      </div>

      <button
        @click="$store.commit('toggleMapStatus')"
        class="px-2 ml-2 h-14 w-14 rounded-lg justify-center flex items-center absolute left-5 top-5 z-50 shadow-xl"
        :class="$store.state.mapView == true ? 'bg-black ' : 'bg-white'"
      >
        <img
          class="w-6 h-6"
          :src="
            $store.state.mapView == false
              ? require('@/assets/marker/mapstatus.svg')
              : require('@/assets/marker/mapstatus_white.svg')
          "
          alt="vehicle map"
        />
      </button>

      <div v-if="!load && markers.length">
        <GmapMap
          ref="gmap"
          :center="position"
          :zoom="zoomLevel"
          :map-type-id="$store.state.mapView ? 'hybrid' : 'roadmap'"
          style="width: 100%; height: 60vh !important"
          :options="{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: false,
          }"
        >
          <GmapMarker
            v-for="m in markers"
            :key="m.id"
            :title="m.title"
            :label="{
              text: m.title, // + '-' + m.speedDirection
              color: 'white',
              className:
                'absolute bottom-4 -right-8 text-xs  px-2 text-xs font-bold bg-blue-700 rounded tracking-widest',
              fontSize: '8.5px',
            }"
            :position="m.position"
            :icon="m.icon"
          >
          </GmapMarker>

          <GmapMarker
            v-show="stateId == 5"
            v-for="m in detail.waypoints"
            :key="m.id + '_way'"
            :title="m.no"
            :label="{
              text: m.no + '. Numaralı Ara Nokta', // + '-' + m.speedDirection
              color: 'white',
              className:
                'absolute bottom-4 -right-8 text-xs  px-2 text-xs font-bold bg-blue-700 rounded tracking-widest',
              fontSize: '8.5px',
            }"
            :position="m.position"
            :icon="m.icon"
          >
          </GmapMarker>

          <GmapMarker
            v-if="detail.planning && detail.planning.state < 8"
            :title="
              detail.planning.state < 4
                ? 'Yükleme Noktası'
                : detail.planning.state != 4 && detail.planning.state < 8
                ? 'Teslim Noktası'
                : ''
            "
            :label="{
              text: detail.planning.adress,
              color: 'white',
              className:
                'absolute bottom-4 -right-8 text-xs  px-2 text-xs font-bold bg-blue-700 rounded tracking-widest',
              fontSize: '8.5px',
            }"
            :position="detail.target"
            :icon="require('../../../../assets/marker/flag.png')"
          >
          </GmapMarker>

          <GmapPolyline
            v-if="taskDetail.polyline && detail"
            v-bind:path.sync="taskDetail.polyline"
            v-bind:options="{ strokeColor: '#C43A3A' }"
          >
          </GmapPolyline>
        </GmapMap>

        <div
          class="position absolute bottom-0 bg-white z-50 py-5 w-full px-8 flex items-start justify-start"
          v-if="stateId == 1 || stateId == 2 || stateId == 3 || stateId == 5"
        >
          <div class="w-4/12">
            <label class="block text-sm font-bold mb-1"
              >Tahmini Varış Süresi</label
            >
            <p>{{ taskDetail.totalMinute }}</p>
          </div>
          <div
            class="w-4/12 px-5 border-r border-black border-opacity-50 border-l"
          >
            <label class="block text-sm font-bold mb-1"
              >Hedeflenen Varış Süresi</label
            >
            <span v-if="detail.planning.targetDate">
              {{ detail.planning.targetDate | dateFormat }}
            </span>
            <span v-else>Teslim Tarihi Belirtilmemiş</span>
          </div>
          <div class="w-4/12 pl-4">
            <label class="block text-sm font-bold mb-1"
              >Hedef Adres

              <span class="ml-3 text-red-500"
                >({{
                  detail.planning.state < 4
                    ? "Yükleme Noktası"
                    : "Boşaltma Noktası"
                }})</span
              >
            </label>
            <p class="text-xs">
              {{ detail.target.adress }}
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="!load && markers.length == 0"
        class="w-full flex items-center justify-center"
        style="height: 50vh"
      >
        <div class="inline text-center">
          <i class="fas fa-exclamation-triangle fa-4x"></i>
          <p class="block mt-5 px-20 leading-7">
            Araç konumu bulunamadı lütfen aracın takip sisteminin doğru
            çalıştığından emin olun !
          </p>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
// NPM
import moment from "moment";

// Networking
import axios from "axios";
import { tracking } from "@/networking/urlmanager";
import { routeEngine } from "@/networking/routeEngine";
//Utils
import { decode } from "../../../../utils/encoder";

export default {
  name: "vehicle-location-detail",
  props: ["vehicle", "stateId"],
  data() {
    return {
      position: { lat: 41.015137, lng: 28.97953 },
      center: { lat: 41.015137, lng: 28.97953 },
      zoomLevel: 5,
      markers: [],
      detail: {},
      taskDetail: {},
      load: true,
    };
  },
  methods: {
    getAll(load = true) {
      this.markers = [];
      this.detail = {};
      this.taskDetail = {};
      this.load = load;
      axios
        .get(tracking.getAll + "?targetVehicle=" + this.vehicle, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.load = false;
          if (Array.isArray(res.data.data)) {
            if (res.data.data.length > 0) {
              let markers = res.data.data.filter(
                (r) =>
                  r.vehicle.toLowerCase() ==
                  this.vehicle.toLowerCase().replace(/\s/g, "")
              );
              this.center = {
                lat: markers[0].latitude,
                lng: markers[0].longitude,
              };
              this.position = {
                lat: markers[0].latitude,
                lng: markers[0].longitude,
              };

              this.markers = markers.map((el) => {
                let list = JSON.parse(el.planning?.waypoints);
                if (!Array.isArray(list)) list = [];

                return {
                  id: el.id,
                  title: el.vehicle,
                  region: el.region,
                  position: {
                    lat: parseFloat(el.latitude),
                    lng: parseFloat(el.longitude),
                  },
                  adress: el.address,
                  vehicle: el.vehicle,
                  dorse: el.dorse,
                  planning: el.planning,
                  driverName: el.driverName,
                  state: el.state,
                  targetDate: moment(el.targetDate).format("LLLL"),
                  waypoints: list.map((item) => {
                    return {
                      date: item.date,
                      no: item.no,
                      amount: item.amount,
                      position: {
                        adress: item.adress,
                        lat: Number(item.latitude),
                        lng: Number(item.longitude),
                      },
                    };
                  }),
                  target:
                    el.planning.state < 4
                      ? {
                          adress: el.outpointAdress,
                          lat: Number(el.outpointLat),
                          lng: Number(el.outpointLng),
                        }
                      : {
                          adress: el.targetAdress,
                          lat: Number(el.targettLat),
                          lng: Number(el.targettLng),
                        },
                  isBulkOperation: el.isBulkOperation,
                  icon: this.positionMarker(el.speed, el.speedDirection),
                };
              });
              this.detail = this.markers[0];

              this.zoomLevel = 8;
              this.getRoute();
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.load = false;
          // this.errorBox(err.response);
        });
    },
    getRoute() {
      if (
        !(
          this.detail.planning &&
          (this.detail.planning.state == 1 ||
            this.detail.planning.state == 2 ||
            this.detail.planning.state == 3 ||
            this.detail.planning.state == 5)
        )
      ) {
        return;
      }

      this.load = true;
      axios
        .post(
          routeEngine.generate,
          {
            from: this.detail.position,
            to: this.detail.target,
            waypoints: this.detail.waypoints.map((item) => {
              return {
                adress: item.position.adress,
                lat: item.position.lat,
                lng: item.position.lng,
              };
            }),
            isToll: 1,
            routeType: "fast",
            shippedHazardousGoods: false,
            isShip: 1,
            isAsphalt: 1,
            fuelRatio: 35,
            isPolyline: 1,
            vehicleTypeId: 5,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.routeEngineToken,
            },
          }
        )
        .then((res) => {
          this.load = true;
          const detail = res.data.data;
          this.taskDetail = {
            state: this.detail.state,
            polyline: decode(detail.routePolyline).polyline,
            customer: this.detail.customer,
            totalMinute: moment()
              .add(detail.totalMinute, "minutes")
              .format("LLLL"),
          };
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.load = false;
        });
    },
    reset() {
      this.load = true;
      this.position = { lat: 41.015137, lng: 28.97953 };
      this.zoomLevel = 5;
      this.markers = [];
    },
  },
  filters: {
    dateFormat(val) {
      return moment(val).format("LLL");
    },
  },
};
</script>
